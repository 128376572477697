// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compare-items-js": () => import("./../../../src/pages/compare-items.js" /* webpackChunkName: "component---src-pages-compare-items-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-billing-address-js": () => import("./../../../src/pages/my-account/billing-address.js" /* webpackChunkName: "component---src-pages-my-account-billing-address-js" */),
  "component---src-pages-my-account-change-password-js": () => import("./../../../src/pages/my-account/change-password.js" /* webpackChunkName: "component---src-pages-my-account-change-password-js" */),
  "component---src-pages-my-account-customer-address-js": () => import("./../../../src/pages/my-account/customer-address.js" /* webpackChunkName: "component---src-pages-my-account-customer-address-js" */),
  "component---src-pages-my-account-notifications-js": () => import("./../../../src/pages/my-account/notifications.js" /* webpackChunkName: "component---src-pages-my-account-notifications-js" */),
  "component---src-pages-my-account-shipping-address-js": () => import("./../../../src/pages/my-account/shipping-address.js" /* webpackChunkName: "component---src-pages-my-account-shipping-address-js" */),
  "component---src-pages-my-account-view-orders-js": () => import("./../../../src/pages/my-account/view-orders.js" /* webpackChunkName: "component---src-pages-my-account-view-orders-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-seller-registration-js": () => import("./../../../src/pages/seller-registration.js" /* webpackChunkName: "component---src-pages-seller-registration-js" */),
  "component---src-pages-stores-js": () => import("./../../../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-category-store-js": () => import("./../../../src/templates/categoryStore.js" /* webpackChunkName: "component---src-templates-category-store-js" */),
  "component---src-templates-product-preview-js": () => import("./../../../src/templates/productPreview.js" /* webpackChunkName: "component---src-templates-product-preview-js" */)
}

